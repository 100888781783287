<template>
  <div>
    <div class="pc margin-top-52">
      <div class="card-wrapper">
      <div class="card-info unselect"
           :class="{'selected-card': inputValue && item.id === inputValue.id}"
           v-for="item in list"
           :key="`hosting-${item.id}`"
           @click="selectItem(item)">
        <div class="flex-between">
          <div class="flex-align">
            <div class="h7 sub">{{ item.name }}</div>
            <div class="tag-primary" v-if="item.name.toLowerCase() === 'basic'">추천</div>
          </div>
          <div class="tag-primary">14일 무료체험</div>
        </div>
        <div class="body2 sub3 padding-top-12">
          <div class="body4">{{ item.desc }}</div>
          <div class="body4" v-if="item.price>0">*월결제</div>
        </div>
        <div class="margin-top-12">
          <div v-if="item.price_type===0">
            <span class="body2-bold main">{{ item.price | currencyNum }}</span>
            <span class="body4 main margin-left-2">원</span>
            <span class="body4 sub3">/</span>
            <span class="body4 sub3 margin-left-4">월</span>
          </div>
          <div class="h7 main" v-else>{{ item.price_text }}</div>
        </div>
      </div>
    </div>
    </div>
    <div class="mobile margin-top-32">
      <div class="card-wrapper">
      <div class="card-info unselect"
           :class="{'selected-card': inputValue && item.id === inputValue.id}"
           v-for="item in list"
           :key="`hosting-${item.id}`"
           @click="selectItem(item)">
        <div class="flex-between margin-bottom-8">
          <div class="flex-align">
            <div class="body3-medium">{{ item.name }}</div>
            <div class="tag-primary" v-if="item.name.toLowerCase() === 'basic'">추천</div>
          </div>
          <div v-if="item.price_type===0">
            <span class="body4-bold main">{{ item.price | currencyNum }}</span>
            <span class="body4 main margin-left-2">원</span>
            <span class="body4 sub3">/</span>
            <span class="body4 sub3 margin-left-4">월</span>
          </div>
          <div class="body4-bold main" v-else>{{ item.price_text }}</div>
        </div>
        <div class="tag-free margin-bottom-8 size-13 weight-500 primary">14일 무료체험</div>
        <div class="body4 sub3">
          {{ item.desc }}<br><span v-if="item.price>0">*월결제</span>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SelectHosting",
    props: {
      value: {
        type: Object
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        inputValue: undefined
      }
    },
    methods: {
      selectItem(item) {
        this.inputValue = item;
        this.$emit('update:value', this.inputValue);
      },
      getData() {
        this.$axios.get('public/launchpack/hosting').then(res=>{
          this.list = res.data.slice(0,2);
          if(this.list.length>0) this.inputValue = this.list[0];
          this.$emit('update:value', this.list[0]);
        })
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .tag-primary
    margin-left 4px
    color $primary
    background-color $primary-light2
    border-radius 50px
    font-size 12px
    padding 2px 8px

  .card-info
    text-align left
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)
      margin auto
      width 624px
    .card-info
      border 1px solid $gray1
      padding 24px
      border-radius 8px
    .selected-card
      padding 23px !important

  .mobile
    .card-info
      border 1px solid $gray1
      padding 16px 20px
      border-radius 8px
      margin-top 10px
    .selected-card
      padding 15px 19px !important

  .introduction-content
    white-space pre-line

  .selected
    color $primary
  .selected-card
    border 2px solid $primary !important
  
  .tag-free
    width 94px
    height 26px
    line-height 26px
    border-radius 4px
    background-color $primary-light2
    text-align center

</style>
